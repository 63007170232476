$primary-theme-color:#01462A;
$secondary-theme-color:#EAB631;
$default-font-white:#fff;
$default-font-grey:#4e4e4e;

@import url("https://fonts.googleapis.com/css2?family=Gilda+Display&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
*{
    margin:0;
    box-sizing: border-box;
}
.mg-t-1{
    margin-top: 1rem;
}
.hero_wrapper{
    height: calc(100dvh - 4rem);
     background: #000000c5;
    color: $default-font-white;
    position: relative;
    width: 100%;
    //top: -5rem;
    display: flex;
    place-items: center;
    &::before{
        content: ' ';
        width: 100%; 
        background: url('imgs/restaurant_bg_hero.jpg');
        position: absolute;
       background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
        height: inherit;
        z-index: -1;


    }
    @media screen and (max-width:520px) {
        height: calc(100dvh - 2.65rem);
        
        
    }
}
.hero_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 2rem;
    @media screen and (max-width:520px) {
        gap: 2rem;
        
    }
}
.logo{
    height: 6rem;
    @media screen and (max-width:520px) {
        height: 5rem;
        
    }
    img{
        display: block;
        width: auto;
        height: inherit;
    }
}
.hero_description{
    text-align: center;
    width: 50%;
    @media screen and (max-width:520px) {
        width: 95%;

    }

    .hero_header{
        color: $secondary-theme-color;
        font-family: 'Gilda Display','Times New Roman', Times, serif;
        font-size: 4.5rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        @media screen and (max-width:520px) {
           font-size: 2.5rem;
           margin-top: 1rem;
           margin-bottom: 1rem;
            
        }
    }
    .hero_message{
        font-size: 1.25rem;
        font-family: 'Roboto','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 300;
      
        svg{
            width: 100%;
        }
        a{
           button{
            color: $secondary-theme-color;
            background: $primary-theme-color;
        }  
        }
        @media screen and (max-width:520px) {
            //font-size: 1.05rem;
            font-size: .9rem;
            svg{
                width: 80%;
            }
            a{
                button{
                    padding: .5rem 1rem;
                }
            }
            
        }
       
    }
}
.hero_slider{
  
 
    
  
    width: 80%;
   //overflow: hidden;
    .slick-slider{
        
    }
    .slick-list{
       
    }

    .slick-track{
       // width: 100dvw !important;
       width: 100%;
    
    }
    .hero_slide{
        display: flex;
       flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    
       
        
       
        .hero_slide_img{
            height: 8rem;
            width: inherit;
            margin-left: auto;
            margin-right: auto;
            img{
                display: block;
                object-fit: cover;
                height: inherit;
                width: auto;;
               
                margin-left: auto;
                margin-right: auto;

            }
        }
        .hero_slide_desc{
            p{

            }
        }

    }
}
.x{
  display: flex;
  p{
    svg{
        height: 1rem;
        fill: $default-font-white;
    }
  
  }  .hide{
        display: none;
    }
}
.scrl-indicator{
    position: absolute;
    bottom: 0;

    svg{
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: .5rem;
        margin-top: .25rem;
        margin-bottom: .25rem;
        animation: 1s infinite alternate updown;
       
        
    }
}
.nav_wrapper{
    position: sticky;
    top: 0;
    z-index: 2;
    height: 4rem;
    padding-left: 6px;
    padding-right: 6px;
    background-color: #101010;
    @media screen and (max-width:520px) {
        height:2.65rem ;
        padding: 0 12px;
        
    }
}
.top_nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;

    #drawer{
        display: none;
        &:checked ~ .drawer-b{
            left: 0;
        }
    }
    .drawer-b{
        width: 20%;
        height: calc(100dvh - 4rem );
        position: fixed;
        background-color: rgba(0, 0, 0, 0.958);
        left: -20%;
        bottom: 0;
        z-index: 1044;
        transition: 1s ease;
        @media screen and (max-width:540px) {
            left: -100%;
            width: 100%;
            z-index: 1053;
            height: calc(100dvh - 2.65rem);
        }
       
        ul{
           // border: 1px solid white;
            margin: 0;
            padding: 0;
            text-align: center;
            list-style: none;
            li{
                padding-top: .5rem;
                padding-bottom: .5rem;
            }
            
           
            
            a{
                text-decoration: none;
                color: $default-font-white;
                &:hover{
                    li{
                        background-color: $primary-theme-color;
                    color: $secondary-theme-color;
                    }
                    
                }

            }
        }

    }

    

    svg{
        height: 20px;
        width: 40px;
        stroke-width: 3;
        stroke:$default-font-white ;
       // animation:updown alternate 2s ;
        @media screen and (max-width:520px) {
            height: 20px;
            width: 25px;
            
        }
        
    }
}

//MSC
button{
    padding: .75rem 3.5rem;
    outline: none;
    border: none;
}
.note{
    margin-top: .25rem;
    margin-bottom: .3rem;
    @media screen and (max-width:540px) {
        font-size: .75rem;
        color: red;
        text-align: center;
        
    }
}
.pay{
    margin-top: .3rem;
    margin-bottom: .3rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    background-color: $secondary-theme-color;

}
.book_a_table{
    background-color: $secondary-theme-color;
    font-weight: 700;
    color: #01462A;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.245);
    transition: background,color .5s;
    &:hover{
        background-color: $primary-theme-color;
        color: $default-font-white;
        cursor: pointer;

    }
    @media screen and (max-width:520px) {  
            padding: .4rem .7rem;
            font-weight: 500;
               
    }

}

.order_button{
    position: fixed;
    bottom: 3rem;
    right: 1rem;
    border-radius: 50%;
    background: $secondary-theme-color;
    opacity: .8;
    height: 4rem;
    width: 4rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 1px #9b9b9b;
    p{
        position: absolute;
        top: 0;
        background-color: $primary-theme-color;
        color: $default-font-white;
        min-width: 1rem;
        min-height: 1rem;
        border-radius: 50%;
        text-align: center;
        font-size: .8rem;
        padding: .1rem;
    }
    button{
        background: none;
        svg{
            width: 2rem;
            height: 2rem;
            stroke-width: 1rem;
            fill: $primary-theme-color;
        }
        
    }

}

.orders{
    display: block;
    position: absolute;   
}

.orders_t{
    width: 100%;
    th,td{
        color:$default-font-grey;
        input{
            width: 30%;
            display: block;
            margin-left: auto;
            margin-right: auto;
            
        }
        @media screen and (max-width:540px) {
            font-size: .8rem;
            
        }
    }
    &{
        th{
            text-align: center;
        }
        th:first-child{
            text-align: left;
        }
        th:last-child,td:last-child{
            text-align: right;
        }
       
    }
    label{
        span{
            color: red !important;
        }
    }


}

.orders_container{
    max-height: 50dvh;
    overflow: scroll;
}

.cartItem-card{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    box-shadow: 1px 1px 2px rgb(234, 234, 234);
    padding: 0.5rem;
    .item-name{
        
        font-size: .8rem;
        span{
            font-size: 1rem;
            font-weight: bold;
        }
    }
    .actions{
        display: flex;
        justify-content: space-between;
        .remove{}
            button{
              font-size: .7rem; 
              padding: .5rem .8rem; 
            }
            

        
    }
    
}
.qty-stepper{
    display: flex;
    gap: .5rem;
}

.total_order{
    text-align: right;
    span{
        font-weight: bolder;
    }
}
.deliveryOptions{
    label{
        @media screen and (max-width:520px) {
            font-size: .8rem;
            
        }
    }

    input{  
        outline: none !important;
        &:hover,&:focus{
                border: 1px solid rgb(130, 174, 255)  !important;
            }
        @media screen and (max-width:520px) {
            width: 100%;
            height: 1.5rem;
          
            
        }
        @media screen and (min-width:1024px) {
            width: 100%;
            height: 2rem;
          
            
        }
        

    }
}
.one-pg{
    //height: 100dvh;
    
    margin-top: 2rem;
    
    
}
.one-pg-head{
    position: absolute;
    height: 10rem;
    width: 100%;
    background:url('imgs/restaurant_bg_hero.jpeg');
    background-size: cover;
    background-position: center;
    //border: 1px solid red;
}
.pg-wrapper{
    margin-left: auto;
    margin-right: auto;
    //background:$default-font-white ;
    box-shadow: 1px 1px 1px $default-font-grey;
    //test
    position: relative;
    top:6rem;
    //height: 100dvh;
    
}
.w80{
    width: 80%;

}
.header{
    margin-bottom: 1rem;
   
    h1{
        font-family: 'Gilda Display',serif,Georgia, 'Times New Roman', Times, serif;
        font-weight: 500;

    }
    h3{

    }
    p{
        font-family: 'Montserrat','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 400;
        color: $default-font-grey;
    }
    hr{
        background-color: $secondary-theme-color;
        border:1px solid $secondary-theme-color;
        margin-top: 1rem;
        
    }
}

.ht1{
    text-align: center;
    margin-bottom: 1rem;
    @media screen and (max-width:540px) {
        margin-bottom: 1rem;    
    }
    hr{
        width: 10%;
        margin-left: auto;
        margin-right: auto;
        
    }
    h1{
        font-size: 2.5rem;
        text-align: center;
        color: $primary-theme-color;
        @media screen and (max-width:540px) {
            font-size: 1.9rem;    
        }
        @media screen and (max-width:935px) and (orientation:'landscape') {
            font: 1.2rem;
            
        }
    }
    p{  
        font-size: .9rem;
        @media screen and (max-width:540px) {
        font-size: 0.8rem;
        @media screen and (max-width:935px) and (orientation:'landscape') {
           font-size: .7rem;
            
        }
        
    }
    
    }
}
//services
.services_content{
    //background-color: red;
    //height: 70dvh;
    display: flex;
    //background: url('./imgs/vegetables.png');
    background-position: left;
    background-repeat: no-repeat;
    //background: url('./imgs/restaurant_bg_hero.jpg');
    background: url('./imgs/wave.png');
    background-size: cover;
    justify-content: center;

    @media screen and (max-width:540px) {
        flex-direction: column;
        
    }
    
  
   
}
#fu{
    font-size: .9rem;
}
.service_img{
    width: 50%;
    background-image: url('./imgs/chef.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    @media screen and (max-width:540px) {
        position: unset;
        width: 100%;
        height: 300px;
        
    }
    &::after{
       // content: 'Brunch ';
        content: '';
        position: absolute;
        top:50%;
        height: 50%;
        width: 40%;
       // box-shadow: 1px 1px 1px 1px grey;
        display: grid;
        place-items: center;
        background-image: url('./imgs/burger_8648590.png');
        background-position: center;
        background-repeat: no-repeat;
        -webkit-text-stroke: #dadada;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: $primary-theme-color;
        place-items: end center;
        font-size: 4rem;
        text-align: center;
        font-weight: 600;
        clip-path: circle(50% at 50% 50%);
        @media screen and (max-width:540px) {
           display: none;
            
        }
   
        
       

    }
    &::before{
      //  content: 'ACES BRUNCH';
        //content: 'ACES';
        //height: 100%;
        width: 100%;
        position: absolute;
        //border: 1px solid red;
        
       // box-shadow: 1px 1px 1px 1px grey;
       // background-image: url('./imgs/136529089_a1e4374c-e98e-4b15-a9ec-a2fe7fc511b9.png');
        background-position: left;
        background-repeat: no-repeat;
        -webkit-text-stroke: $secondary-theme-color;
        -webkit-text-stroke-width: 2px;
       // -webkit-text-fill-color:$primary-theme-color ;
        color: transparent;
        display: grid;
        place-items: top center;
        font-size: 4rem;
        text-align: center;
        font-weight: 600;
        //background-color: #01462A;
        text-shadow: -1px -1px 5px $primary-theme-color;

       
    }

}
.services_desc{
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px solid indigo;
    box-shadow: 1px 1px 2px #bebebe;
    width: 50%;
    flex-direction: column;
    padding: 1rem;
    gap: 2rem;
   left: -5rem;
   position: relative;
   background-color: #ffffffe7;
    border-radius: 20px;
    @media screen and (max-width:540px) {
       left: unset;
       top: -7rem;
        width: 100%;
       
    }
   
    .title{
        margin-bottom: 2rem;
        text-align: center;
     
       
        h2{
             font-family: 'Gilda Display','Times New Roman', Times, serif;
             font-size: 2rem;
             text-transform: capitalize;    
             color: $primary-theme-color;  
             @media screen and (max-width:540px) {
              font-size: 2rem;
                
            }
             
        }
        hr{
            width: 20%;
            margin-bottom: .5rem;
            margin-top: .5rem;
            margin-left: auto;
            margin-right: auto;
            background-color: $secondary-theme-color;
        border:1px solid $secondary-theme-color;
        @media screen and (max-width:540px) {
            width: 10%;
            
        }
        }
        p{
            color: $default-font-grey;
            
        }
       

    }

}
.menus{
   width: 90%;
   margin-left: auto;
   margin-right: auto;
    display: flex;
    gap: 1rem;
    @media screen and (max-width:540px) {
        flex-direction: column;
     width: 100%;
        
    }
}
.menu-pg{
    flex-wrap: wrap;
    @media screen and (max-width:540px) {
        flex-direction: row !important;
        //gap: unset;
        justify-content: space-around;
        
    }
    .menu_card{
        @media screen and (max-width:540px) {
         width: calc((100% / 2) - 1rem);
           
         .menu_desc{
        .default-btn{
            button{
                cursor: pointer !important;

             font-size: .7rem;
           
            }
            
        } 
         .menu_name{
        color: $default-font-grey;
        font-weight: 400;
    }
    }
    
  
        }
     
    }
    .menu_img{
        @media screen and (max-width:540px) {
           height: 3rem !important;
               
           }

        
    }
    .menu_name{
        @media screen and (max-width:540px) {
           
               font-size: .9rem;
           }
        
    }

}
.menu_card{
    display: flex;
    flex-direction: column;
    background-color: #eaeaea53;
    box-shadow: .5px .5px   2px #d5d5d5;
    padding: 1rem;
    gap: .5rem;
    .menu_img{
       text-align: center;
       height: 150px;
        img{
            object-fit: cover;
            height: inherit;
            width: auto;
            
        }
        
    }
    .menu_desc{
        display: flex;
        
        flex-direction: column;
        gap: .5rem;
        p{
            text-align: center;
            width: 100%;
            font-weight: 500;
        }
        .menu_name{
            font-family: 'Gilda Display','Times New Roman', Times, serif;
            font-weight: 600;
        }
        .price{
            font-size:1.3rem ;
        }
        button{
            background: $secondary-theme-color;
            padding: 0.25rem .6rem;
            border-radius: 50%;
            font-size: 1.2rem;
            color: $primary-theme-color;
            transition: all 1s ease;
            &:hover{
                cursor: pointer;
                background-color: $primary-theme-color;
                color: $secondary-theme-color;

            }
        }
        .default-btn{
            button{
                background: $secondary-theme-color;
                padding: 0.5rem 1rem;
                border-radius: unset;
                font-size: 1rem;
                color: $primary-theme-color;
                transition: all 1s ease;
                &:hover{
                    cursor: pointer;
                    background-color: $primary-theme-color;
                    color: $secondary-theme-color;
    
                }
            }
        }
    }

}

.menu_card-2{
    border-radius: 5px;
    box-shadow: 1px 1px 7px #bebebe;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    padding: 1rem;
    .menu-img{
        height: 10rem;
        img{
            height: inherit;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .menu-desc{
        display: flex;
        flex-direction: column;
        gap: .5rem;
        .menu-name{
            font-weight: 700;
            text-align: center;
        }
        .subtitle{
            padding-left: .5rem;
            font-weight: 600;
        }
        .opt-container{
            display: flex;
            flex-wrap: wrap;
            gap: .5rem;
            button{
                padding-left: 0.5rem;
                padding-right: .5rem;
                display: inline-block;
                background: $secondary-theme-color;
                color: $primary-theme-color;
                font-weight: 600;
                box-shadow: 1px 1px 2px #9b9b9b;
            }
        }
        .button-t2{
            button{
            padding-left: 0.5rem;
            padding-right: .5rem;
            display: inline-block;
            background: $primary-theme-color;
            color: $secondary-theme-color;
            font-weight: 600;
            }
        }
        hr{
            width: 95%;
            margin-left: auto;
            margin-right: auto;
        }
        .add_order{
            button{
                margin-left: auto;
                margin-right: auto;
                display: block;
            }
        }

    }

}
.menu_card-3{
    margin-bottom: .5rem;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
   // grid-template-rows: 1fr 2fr;
    width: 98%;
    padding:.5rem ;
    //box-shadow: 1px 1px 2px #c3c3c3;
    box-shadow: 1px 1px 2px #f6f6f6;
    
    .menu_img{
        display: none;
    }
    .afro_img{
        height: 3rem;
        //text-align: center;
        img{
            height: inherit;
            width: auto;
        }

    }
    .description{
        grid-column: 1 / span 2;
        grid-row: 1;
        .menu_name{
            font-weight: 700;
            color: $primary-theme-color;
            padding-bottom:.5rem ;
            @media screen and (max-width:520px) {
                font-size: .8rem;
                
            }
            @media screen and (max-width:935px) and (orientation:'landscape') {
               font-size: .9rem;
                
            }
        
    }
        
    }

    .desc_center{
        grid-row:1 / span 2 ;
        align-self: center;
    }
    .option_details{
        grid-column: 1 / span 2;
        grid-row: 2;
        p{
            @media screen and (max-width:520px) {
                font-size: .7rem;
                
            }
            @media screen and (max-width:935px) and (orientation:'landscape') {
                font-size: .7rem;
                
            }
        }
       

    }
    .opt_none{
        display: none;
    }
    .buy-container{
        grid-column: 3;
        grid-row: 1 / span 2;
        align-self: center;
       // text-align: center;
       text-align: right;
       display: flex;
       flex-direction: column;
       gap: .5rem;
        .price{
            //font-size: 1.5rem;
            font-size: 1.2rem;
            @media screen and (max-width:520px) {
                font-size: 1rem;
                
            }
        }
        .default-btn{
            button{
                cursor: pointer;
                padding:.2rem 1rem;
                //width: 100%;
                background: $secondary-theme-color;
                color: $primary-theme-color;
                box-shadow: 1px 1px 2px #bebebe;
            }
        }
    }
}






.discover-more{
    margin-top: 1.5rem;
    button{
        background-color: $secondary-theme-color;
        font-weight: 700;
        color: #01462A;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.245);
        transition: background,color .5s;
        &:hover{
            background-color: $primary-theme-color;
            color: $default-font-white;
            cursor: pointer;
    
        }
    }
}

.itms_3{
    width: calc(100% / 3 );
    @media screen and (max-width:540px) {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        
    }

}
.booking-header{
    display: flex;
    align-items: center;
    gap: 1rem;
    color: $primary-theme-color;
    border-bottom: 1px solid $default-font-grey;
    padding-bottom: .50rem;
    margin-bottom: 2rem;

}
//Form Styles
.form-reservation{
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem;
    //border: 1px solid $default-font-grey;
    position: relative;
    //top: -5rem;
    box-shadow: 1px 1px 3px rgb(177, 177, 177);
    background-color: $default-font-white;

    @media screen and (max-width:540px) {
        width: 95%;
        
    }

    label,p{
        color: $primary-theme-color;
        margin-bottom: .3rem;
    }
    p{
        font-weight: 600;
        margin-bottom: 1rem;
    }
    input{
        height: 2rem;
    }
}
.form-g{
    margin-top: 1rem;
    margin-bottom: 1rem;
    
    input,textarea{
        width: 100%;
      
    }

   
}
.form-g2{
    display: flex;
    justify-content: space-between;
    input{
        width: 100%;
    }
}
label{
    display: block;
}
.submit-g{
    text-align: right;
    button{
        background: $primary-theme-color;
        color: $default-font-white;
        cursor: pointer;
        &:hover{
            background-color: $secondary-theme-color;
            color: $primary-theme-color;
        }
    }
}


.filter-container{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 90%;
    padding-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    position: sticky;
    top: 4rem;
    background: $default-font-white;
  
    
    @media screen and (max-width:540px) {
        width: 100%;
        padding-left: .5rem;
        padding-right: .5rem;
        top: 2.6rem;
        
    }  
    @media screen and (max-width:935px) and (orientation:'landscape') {
        width: 95%;
        top: 3.15rem;
        
    }
    hr{
        border: .3px solid #9b9b9b;
        background: none;

    }
}
.filter-buttons{
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;  
    background-color: #f4f4f4;
    padding: .5rem;
    button{
        display: block;
        padding-left:1rem ;
        padding-right: 1rem;
        gap: 1rem;
        color: $primary-theme-color;
        background-color: $secondary-theme-color;
        @media screen and (max-width:540px) {
            font-size: .8rem;
            padding: .5rem;
            
        }
        @media screen and (max-width:935px) and (orientation:'landscape') {
            padding: .5rem 1rem;
            font-size: .8rem;
            
        }

    }

}

//animations
@keyframes updown {
  
    to{
        transform: translateY(5px);
        fill: $default-font-white;
    }
    
}
@keyframes afro_bg_anime {
    0%{
        background: linear-gradient(90deg, rgba(2,96,58,1) 0%, rgba(0,82,48,1) 55%, rgb(0, 131, 78) 100%);
    }
    50%{
        background: linear-gradient(135deg, rgba(2,96,58,1) 0%, rgba(0,82,48,1) 55%, rgb(0, 98, 59) 100%);
    }
    100%{
        background: linear-gradient(180deg, rgba(2,96,58,1) 0%, rgba(0,82,48,1) 55%, rgb(0, 131, 78) 100%);
    }
    
}

.afroweekend_ad{
    position: absolute;
    top: 4rem;
    z-index: 1;
    width: 100%;
    //background: #fff;
    display: flex;
    padding: .5rem;
    justify-content: space-between;
    align-items: center;
    //background: $primary-theme-color;
    background: linear-gradient(90deg, rgba(2,96,58,1) 0%, rgba(0,82,48,1) 55%, rgb(0, 131, 78) 100%);
    animation: afro_bg_anime 2s alternate infinite ease-in-out ;
    a{
        button{
            padding: .5rem .7rem;
            background: $secondary-theme-color;
            color: $primary-theme-color;
            
        }
    }
    svg{
        display: block;
        height: inherit;
        width: 150px;
    }
    @media screen and (max-width:520px)  {
        top:2.65rem
        
    }

}
.afwkend{
    position: sticky;
    top:1.65rem;
    background: #fff !important;
    padding-top: 1rem;
}
.err{
    font-size: .7rem;
    color: red;
}
.sc_wrapper{
    height: calc(100dvh - 4rem);
    display: grid;
    align-items: center;
}
.order-status-card{
    background-color: rgba(244, 244, 244, 0.51);
    box-shadow: 1px 1px 1px rgb(198, 198, 198);
    border-radius: 10px;
    width: 90%;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
   // border: 1px solid red;
    margin-top: 1rem;
    flex-direction: column;
    text-align: center;
    gap: 2rem;
    height: 50dvh;
    justify-content: center;

    svg{
       // border: 1px solid yellow;
        display: block;
        width: 100px;
        height: 100px;
    }
    .successIco{
        
       //width: 100px;
        //height: 100px;
        
        svg{
             g{border: 1px solid blue;
                stroke: none;
             stroke-width: 0;
             stroke-dasharray: none;
              stroke-linecap: butt; 
              stroke-linejoin: miter; 
              stroke-miterlimit: 10;
               fill: none; 
            fill-rule: nonzero; opacity: 1;
            transform:translate(1.4065934065934016 1.4065934065934016);
            transform:  scale(2.81 2.81);

            path:first-child{
                stroke: none; 
                stroke-width: 1; 
                stroke-dasharray: none;
                 stroke-linecap: butt;
                  stroke-linejoin: miter; 
                  stroke-miterlimit: 10; 
                  fill: rgb(0,165,16); 
                  fill-rule: nonzero; 
                  opacity: 1;
                  transform: matrix(1 0 0 1 0 0) ;
                  stroke-linecap:round;

            }
            path:nth-child(2){
                stroke: none; 
                stroke-width: 1;
                 stroke-dasharray: none;
                  stroke-linecap: butt; 
                  stroke-linejoin: miter;
                   stroke-miterlimit: 10;
                    fill: rgb(0,165,16);
                     fill-rule: nonzero; 
                     opacity: 1;
                      transform: matrix(1 0 0 1 0 0);
                stroke-linecap:round;

            }
        }

        }

    }
    .failIco{
        svg{
            g{
                stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1; 
                path:first-child{
                    stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;
                    stroke-linecap: round;

                }
                path:nth-child(2){
                    stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;
                    stroke-dasharray: round;
                }
                path:nth-child(3){
                    stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;
                    stroke-linecap:round

                }
            }
        }

    }
    
}
.success_btn{
    background: rgb(47, 191, 62);
    color: #fff;
}
.fail_btn{
    background: rgb(191, 47, 47);
    color: #fff;
}