@import url("https://fonts.googleapis.com/css2?family=Gilda+Display&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  margin: 0;
  box-sizing: border-box;
}

.mg-t-1 {
  margin-top: 1rem;
}

.hero_wrapper {
  height: calc(100dvh - 4rem);
  background: rgba(0, 0, 0, 0.7725490196);
  color: #fff;
  position: relative;
  width: 100%;
  display: flex;
  place-items: center;
}
.hero_wrapper::before {
  content: " ";
  width: 100%;
  background: url("imgs/restaurant_bg_hero.jpg");
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: inherit;
  z-index: -1;
}
@media screen and (max-width: 520px) {
  .hero_wrapper {
    height: calc(100dvh - 2.65rem);
  }
}

.hero_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2rem;
}
@media screen and (max-width: 520px) {
  .hero_content {
    gap: 2rem;
  }
}

.logo {
  height: 6rem;
}
@media screen and (max-width: 520px) {
  .logo {
    height: 5rem;
  }
}
.logo img {
  display: block;
  width: auto;
  height: inherit;
}

.hero_description {
  text-align: center;
  width: 50%;
}
@media screen and (max-width: 520px) {
  .hero_description {
    width: 95%;
  }
}
.hero_description .hero_header {
  color: #EAB631;
  font-family: "Gilda Display", "Times New Roman", Times, serif;
  font-size: 4.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 520px) {
  .hero_description .hero_header {
    font-size: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.hero_description .hero_message {
  font-size: 1.25rem;
  font-family: "Roboto", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
}
.hero_description .hero_message svg {
  width: 100%;
}
.hero_description .hero_message a button {
  color: #EAB631;
  background: #01462A;
}
@media screen and (max-width: 520px) {
  .hero_description .hero_message {
    font-size: 0.9rem;
  }
  .hero_description .hero_message svg {
    width: 80%;
  }
  .hero_description .hero_message a button {
    padding: 0.5rem 1rem;
  }
}

.hero_slider {
  width: 80%;
}
.hero_slider .slick-track {
  width: 100%;
}
.hero_slider .hero_slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.hero_slider .hero_slide .hero_slide_img {
  height: 8rem;
  width: inherit;
  margin-left: auto;
  margin-right: auto;
}
.hero_slider .hero_slide .hero_slide_img img {
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  height: inherit;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
.x {
  display: flex;
}
.x p svg {
  height: 1rem;
  fill: #fff;
}
.x .hide {
  display: none;
}

.scrl-indicator {
  position: absolute;
  bottom: 0;
}
.scrl-indicator svg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  animation: 1s infinite alternate updown;
}

.nav_wrapper {
  position: sticky;
  top: 0;
  z-index: 2;
  height: 4rem;
  padding-left: 6px;
  padding-right: 6px;
  background-color: #101010;
}
@media screen and (max-width: 520px) {
  .nav_wrapper {
    height: 2.65rem;
    padding: 0 12px;
  }
}

.top_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
}
.top_nav #drawer {
  display: none;
}
.top_nav #drawer:checked ~ .drawer-b {
  left: 0;
}
.top_nav .drawer-b {
  width: 20%;
  height: calc(100dvh - 4rem);
  position: fixed;
  background-color: rgba(0, 0, 0, 0.958);
  left: -20%;
  bottom: 0;
  z-index: 1044;
  transition: 1s ease;
}
@media screen and (max-width: 540px) {
  .top_nav .drawer-b {
    left: -100%;
    width: 100%;
    z-index: 1053;
    height: calc(100dvh - 2.65rem);
  }
}
.top_nav .drawer-b ul {
  margin: 0;
  padding: 0;
  text-align: center;
  list-style: none;
}
.top_nav .drawer-b ul li {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.top_nav .drawer-b ul a {
  text-decoration: none;
  color: #fff;
}
.top_nav .drawer-b ul a:hover li {
  background-color: #01462A;
  color: #EAB631;
}
.top_nav svg {
  height: 20px;
  width: 40px;
  stroke-width: 3;
  stroke: #fff;
}
@media screen and (max-width: 520px) {
  .top_nav svg {
    height: 20px;
    width: 25px;
  }
}

button {
  padding: 0.75rem 3.5rem;
  outline: none;
  border: none;
}

.note {
  margin-top: 0.25rem;
  margin-bottom: 0.3rem;
}
@media screen and (max-width: 540px) {
  .note {
    font-size: 0.75rem;
    color: red;
    text-align: center;
  }
}

.pay {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  background-color: #EAB631;
}

.book_a_table {
  background-color: #EAB631;
  font-weight: 700;
  color: #01462A;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.245);
  transition: background, color 0.5s;
}
.book_a_table:hover {
  background-color: #01462A;
  color: #fff;
  cursor: pointer;
}
@media screen and (max-width: 520px) {
  .book_a_table {
    padding: 0.4rem 0.7rem;
    font-weight: 500;
  }
}

.order_button {
  position: fixed;
  bottom: 3rem;
  right: 1rem;
  border-radius: 50%;
  background: #EAB631;
  opacity: 0.8;
  height: 4rem;
  width: 4rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 1px #9b9b9b;
}
.order_button p {
  position: absolute;
  top: 0;
  background-color: #01462A;
  color: #fff;
  min-width: 1rem;
  min-height: 1rem;
  border-radius: 50%;
  text-align: center;
  font-size: 0.8rem;
  padding: 0.1rem;
}
.order_button button {
  background: none;
}
.order_button button svg {
  width: 2rem;
  height: 2rem;
  stroke-width: 1rem;
  fill: #01462A;
}

.orders {
  display: block;
  position: absolute;
}

.orders_t {
  width: 100%;
}
.orders_t th, .orders_t td {
  color: #4e4e4e;
}
.orders_t th input, .orders_t td input {
  width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 540px) {
  .orders_t th, .orders_t td {
    font-size: 0.8rem;
  }
}
.orders_t th {
  text-align: center;
}
.orders_t th:first-child {
  text-align: left;
}
.orders_t th:last-child, .orders_t td:last-child {
  text-align: right;
}
.orders_t label span {
  color: red !important;
}

.orders_container {
  max-height: 50dvh;
  overflow: scroll;
}

.cartItem-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  box-shadow: 1px 1px 2px rgb(234, 234, 234);
  padding: 0.5rem;
}
.cartItem-card .item-name {
  font-size: 0.8rem;
}
.cartItem-card .item-name span {
  font-size: 1rem;
  font-weight: bold;
}
.cartItem-card .actions {
  display: flex;
  justify-content: space-between;
}
.cartItem-card .actions button {
  font-size: 0.7rem;
  padding: 0.5rem 0.8rem;
}

.qty-stepper {
  display: flex;
  gap: 0.5rem;
}

.total_order {
  text-align: right;
}
.total_order span {
  font-weight: bolder;
}

@media screen and (max-width: 520px) {
  .deliveryOptions label {
    font-size: 0.8rem;
  }
}
.deliveryOptions input {
  outline: none !important;
}
.deliveryOptions input:hover, .deliveryOptions input:focus {
  border: 1px solid rgb(130, 174, 255) !important;
}
@media screen and (max-width: 520px) {
  .deliveryOptions input {
    width: 100%;
    height: 1.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .deliveryOptions input {
    width: 100%;
    height: 2rem;
  }
}

.one-pg {
  margin-top: 2rem;
}

.one-pg-head {
  position: absolute;
  height: 10rem;
  width: 100%;
  background: url("imgs/restaurant_bg_hero.jpeg");
  background-size: cover;
  background-position: center;
}

.pg-wrapper {
  margin-left: auto;
  margin-right: auto;
  box-shadow: 1px 1px 1px #4e4e4e;
  position: relative;
  top: 6rem;
}

.w80 {
  width: 80%;
}

.header {
  margin-bottom: 1rem;
}
.header h1 {
  font-family: "Gilda Display", serif, Georgia, "Times New Roman", Times, serif;
  font-weight: 500;
}
.header p {
  font-family: "Montserrat", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  color: #4e4e4e;
}
.header hr {
  background-color: #EAB631;
  border: 1px solid #EAB631;
  margin-top: 1rem;
}

.ht1 {
  text-align: center;
  margin-bottom: 1rem;
}
@media screen and (max-width: 540px) {
  .ht1 {
    margin-bottom: 1rem;
  }
}
.ht1 hr {
  width: 10%;
  margin-left: auto;
  margin-right: auto;
}
.ht1 h1 {
  font-size: 2.5rem;
  text-align: center;
  color: #01462A;
}
@media screen and (max-width: 540px) {
  .ht1 h1 {
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 935px) and (orientation: landscape) {
  .ht1 h1 {
    font: 1.2rem;
  }
}
.ht1 p {
  font-size: 0.9rem;
}
@media screen and (max-width: 540px) {
  .ht1 p {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 540px) and (max-width: 935px) and (orientation: landscape) {
  .ht1 p {
    font-size: 0.7rem;
  }
}

.services_content {
  display: flex;
  background-position: left;
  background-repeat: no-repeat;
  background: url("./imgs/wave.png");
  background-size: cover;
  justify-content: center;
}
@media screen and (max-width: 540px) {
  .services_content {
    flex-direction: column;
  }
}

#fu {
  font-size: 0.9rem;
}

.service_img {
  width: 50%;
  background-image: url("./imgs/chef.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}
@media screen and (max-width: 540px) {
  .service_img {
    position: unset;
    width: 100%;
    height: 300px;
  }
}
.service_img::after {
  content: "";
  position: absolute;
  top: 50%;
  height: 50%;
  width: 40%;
  display: grid;
  place-items: center;
  background-image: url("./imgs/burger_8648590.png");
  background-position: center;
  background-repeat: no-repeat;
  -webkit-text-stroke: #dadada;
  -webkit-text-stroke-width: 1px;
  -webkit-text-fill-color: #01462A;
  place-items: end center;
  font-size: 4rem;
  text-align: center;
  font-weight: 600;
  -webkit-clip-path: circle(50% at 50% 50%);
          clip-path: circle(50% at 50% 50%);
}
@media screen and (max-width: 540px) {
  .service_img::after {
    display: none;
  }
}
.service_img::before {
  width: 100%;
  position: absolute;
  background-position: left;
  background-repeat: no-repeat;
  -webkit-text-stroke: #EAB631;
  -webkit-text-stroke-width: 2px;
  color: transparent;
  display: grid;
  place-items: top center;
  font-size: 4rem;
  text-align: center;
  font-weight: 600;
  text-shadow: -1px -1px 5px #01462A;
}

.services_desc {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 2px #bebebe;
  width: 50%;
  flex-direction: column;
  padding: 1rem;
  gap: 2rem;
  left: -5rem;
  position: relative;
  background-color: rgba(255, 255, 255, 0.9058823529);
  border-radius: 20px;
}
@media screen and (max-width: 540px) {
  .services_desc {
    left: unset;
    top: -7rem;
    width: 100%;
  }
}
.services_desc .title {
  margin-bottom: 2rem;
  text-align: center;
}
.services_desc .title h2 {
  font-family: "Gilda Display", "Times New Roman", Times, serif;
  font-size: 2rem;
  text-transform: capitalize;
  color: #01462A;
}
@media screen and (max-width: 540px) {
  .services_desc .title h2 {
    font-size: 2rem;
  }
}
.services_desc .title hr {
  width: 20%;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  background-color: #EAB631;
  border: 1px solid #EAB631;
}
@media screen and (max-width: 540px) {
  .services_desc .title hr {
    width: 10%;
  }
}
.services_desc .title p {
  color: #4e4e4e;
}

.menus {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 1rem;
}
@media screen and (max-width: 540px) {
  .menus {
    flex-direction: column;
    width: 100%;
  }
}

.menu-pg {
  flex-wrap: wrap;
}
@media screen and (max-width: 540px) {
  .menu-pg {
    flex-direction: row !important;
    justify-content: space-around;
  }
}
@media screen and (max-width: 540px) {
  .menu-pg .menu_card {
    width: calc(50% - 1rem);
  }
  .menu-pg .menu_card .menu_desc .default-btn button {
    cursor: pointer !important;
    font-size: 0.7rem;
  }
  .menu-pg .menu_card .menu_desc .menu_name {
    color: #4e4e4e;
    font-weight: 400;
  }
}
@media screen and (max-width: 540px) {
  .menu-pg .menu_img {
    height: 3rem !important;
  }
}
@media screen and (max-width: 540px) {
  .menu-pg .menu_name {
    font-size: 0.9rem;
  }
}

.menu_card {
  display: flex;
  flex-direction: column;
  background-color: rgba(234, 234, 234, 0.3254901961);
  box-shadow: 0.5px 0.5px 2px #d5d5d5;
  padding: 1rem;
  gap: 0.5rem;
}
.menu_card .menu_img {
  text-align: center;
  height: 150px;
}
.menu_card .menu_img img {
  -o-object-fit: cover;
     object-fit: cover;
  height: inherit;
  width: auto;
}
.menu_card .menu_desc {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.menu_card .menu_desc p {
  text-align: center;
  width: 100%;
  font-weight: 500;
}
.menu_card .menu_desc .menu_name {
  font-family: "Gilda Display", "Times New Roman", Times, serif;
  font-weight: 600;
}
.menu_card .menu_desc .price {
  font-size: 1.3rem;
}
.menu_card .menu_desc button {
  background: #EAB631;
  padding: 0.25rem 0.6rem;
  border-radius: 50%;
  font-size: 1.2rem;
  color: #01462A;
  transition: all 1s ease;
}
.menu_card .menu_desc button:hover {
  cursor: pointer;
  background-color: #01462A;
  color: #EAB631;
}
.menu_card .menu_desc .default-btn button {
  background: #EAB631;
  padding: 0.5rem 1rem;
  border-radius: unset;
  font-size: 1rem;
  color: #01462A;
  transition: all 1s ease;
}
.menu_card .menu_desc .default-btn button:hover {
  cursor: pointer;
  background-color: #01462A;
  color: #EAB631;
}

.menu_card-2 {
  border-radius: 5px;
  box-shadow: 1px 1px 7px #bebebe;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  padding: 1rem;
}
.menu_card-2 .menu-img {
  height: 10rem;
}
.menu_card-2 .menu-img img {
  height: inherit;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.menu_card-2 .menu-desc {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.menu_card-2 .menu-desc .menu-name {
  font-weight: 700;
  text-align: center;
}
.menu_card-2 .menu-desc .subtitle {
  padding-left: 0.5rem;
  font-weight: 600;
}
.menu_card-2 .menu-desc .opt-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.menu_card-2 .menu-desc .opt-container button {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: inline-block;
  background: #EAB631;
  color: #01462A;
  font-weight: 600;
  box-shadow: 1px 1px 2px #9b9b9b;
}
.menu_card-2 .menu-desc .button-t2 button {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: inline-block;
  background: #01462A;
  color: #EAB631;
  font-weight: 600;
}
.menu_card-2 .menu-desc hr {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.menu_card-2 .menu-desc .add_order button {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.menu_card-3 {
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 98%;
  padding: 0.5rem;
  box-shadow: 1px 1px 2px #f6f6f6;
}
.menu_card-3 .menu_img {
  display: none;
}
.menu_card-3 .afro_img {
  height: 3rem;
}
.menu_card-3 .afro_img img {
  height: inherit;
  width: auto;
}
.menu_card-3 .description {
  grid-column: 1/span 2;
  grid-row: 1;
}
.menu_card-3 .description .menu_name {
  font-weight: 700;
  color: #01462A;
  padding-bottom: 0.5rem;
}
@media screen and (max-width: 520px) {
  .menu_card-3 .description .menu_name {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 935px) and (orientation: landscape) {
  .menu_card-3 .description .menu_name {
    font-size: 0.9rem;
  }
}
.menu_card-3 .desc_center {
  grid-row: 1/span 2;
  align-self: center;
}
.menu_card-3 .option_details {
  grid-column: 1/span 2;
  grid-row: 2;
}
@media screen and (max-width: 520px) {
  .menu_card-3 .option_details p {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 935px) and (orientation: landscape) {
  .menu_card-3 .option_details p {
    font-size: 0.7rem;
  }
}
.menu_card-3 .opt_none {
  display: none;
}
.menu_card-3 .buy-container {
  grid-column: 3;
  grid-row: 1/span 2;
  align-self: center;
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.menu_card-3 .buy-container .price {
  font-size: 1.2rem;
}
@media screen and (max-width: 520px) {
  .menu_card-3 .buy-container .price {
    font-size: 1rem;
  }
}
.menu_card-3 .buy-container .default-btn button {
  cursor: pointer;
  padding: 0.2rem 1rem;
  background: #EAB631;
  color: #01462A;
  box-shadow: 1px 1px 2px #bebebe;
}

.discover-more {
  margin-top: 1.5rem;
}
.discover-more button {
  background-color: #EAB631;
  font-weight: 700;
  color: #01462A;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.245);
  transition: background, color 0.5s;
}
.discover-more button:hover {
  background-color: #01462A;
  color: #fff;
  cursor: pointer;
}

.itms_3 {
  width: 33.3333333333%;
}
@media screen and (max-width: 540px) {
  .itms_3 {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

.booking-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #01462A;
  border-bottom: 1px solid #4e4e4e;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
}

.form-reservation {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  position: relative;
  box-shadow: 1px 1px 3px rgb(177, 177, 177);
  background-color: #fff;
}
@media screen and (max-width: 540px) {
  .form-reservation {
    width: 95%;
  }
}
.form-reservation label, .form-reservation p {
  color: #01462A;
  margin-bottom: 0.3rem;
}
.form-reservation p {
  font-weight: 600;
  margin-bottom: 1rem;
}
.form-reservation input {
  height: 2rem;
}

.form-g {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.form-g input, .form-g textarea {
  width: 100%;
}

.form-g2 {
  display: flex;
  justify-content: space-between;
}
.form-g2 input {
  width: 100%;
}

label {
  display: block;
}

.submit-g {
  text-align: right;
}
.submit-g button {
  background: #01462A;
  color: #fff;
  cursor: pointer;
}
.submit-g button:hover {
  background-color: #EAB631;
  color: #01462A;
}

.filter-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 90%;
  padding-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  position: sticky;
  top: 4rem;
  background: #fff;
}
@media screen and (max-width: 540px) {
  .filter-container {
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    top: 2.6rem;
  }
}
@media screen and (max-width: 935px) and (orientation: landscape) {
  .filter-container {
    width: 95%;
    top: 3.15rem;
  }
}
.filter-container hr {
  border: 0.3px solid #9b9b9b;
  background: none;
}

.filter-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  background-color: #f4f4f4;
  padding: 0.5rem;
}
.filter-buttons button {
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 1rem;
  color: #01462A;
  background-color: #EAB631;
}
@media screen and (max-width: 540px) {
  .filter-buttons button {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
}
@media screen and (max-width: 935px) and (orientation: landscape) {
  .filter-buttons button {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }
}

@keyframes updown {
  to {
    transform: translateY(5px);
    fill: #fff;
  }
}
@keyframes afro_bg_anime {
  0% {
    background: linear-gradient(90deg, rgb(2, 96, 58) 0%, rgb(0, 82, 48) 55%, rgb(0, 131, 78) 100%);
  }
  50% {
    background: linear-gradient(135deg, rgb(2, 96, 58) 0%, rgb(0, 82, 48) 55%, rgb(0, 98, 59) 100%);
  }
  100% {
    background: linear-gradient(180deg, rgb(2, 96, 58) 0%, rgb(0, 82, 48) 55%, rgb(0, 131, 78) 100%);
  }
}
.afroweekend_ad {
  position: absolute;
  top: 4rem;
  z-index: 1;
  width: 100%;
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, rgb(2, 96, 58) 0%, rgb(0, 82, 48) 55%, rgb(0, 131, 78) 100%);
  animation: afro_bg_anime 2s alternate infinite ease-in-out;
}
.afroweekend_ad a button {
  padding: 0.5rem 0.7rem;
  background: #EAB631;
  color: #01462A;
}
.afroweekend_ad svg {
  display: block;
  height: inherit;
  width: 150px;
}
@media screen and (max-width: 520px) {
  .afroweekend_ad {
    top: 2.65rem;
  }
}

.afwkend {
  position: sticky;
  top: 1.65rem;
  background: #fff !important;
  padding-top: 1rem;
}

.err {
  font-size: 0.7rem;
  color: red;
}

.sc_wrapper {
  height: calc(100dvh - 4rem);
  display: grid;
  align-items: center;
}

.order-status-card {
  background-color: rgba(244, 244, 244, 0.51);
  box-shadow: 1px 1px 1px rgb(198, 198, 198);
  border-radius: 10px;
  width: 90%;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  flex-direction: column;
  text-align: center;
  gap: 2rem;
  height: 50dvh;
  justify-content: center;
}
.order-status-card svg {
  display: block;
  width: 100px;
  height: 100px;
}
.order-status-card .successIco svg g {
  border: 1px solid blue;
  stroke: none;
  stroke-width: 0;
  stroke-dasharray: none;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 10;
  fill: none;
  fill-rule: nonzero;
  opacity: 1;
  transform: translate(1.4065934066 1.4065934066);
  transform: scale(2.81 2.81);
}
.order-status-card .successIco svg g path:first-child {
  stroke: none;
  stroke-width: 1;
  stroke-dasharray: none;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 10;
  fill: rgb(0, 165, 16);
  fill-rule: nonzero;
  opacity: 1;
  transform: matrix(1 0 0 1 0 0);
  stroke-linecap: round;
}
.order-status-card .successIco svg g path:nth-child(2) {
  stroke: none;
  stroke-width: 1;
  stroke-dasharray: none;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 10;
  fill: rgb(0, 165, 16);
  fill-rule: nonzero;
  opacity: 1;
  transform: matrix(1 0 0 1 0 0);
  stroke-linecap: round;
}
.order-status-card .failIco svg g {
  stroke: none;
  stroke-width: 0;
  stroke-dasharray: none;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 10;
  fill: none;
  fill-rule: nonzero;
  opacity: 1;
}
.order-status-card .failIco svg g path:first-child {
  stroke: none;
  stroke-width: 1;
  stroke-dasharray: none;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 10;
  fill: rgb(236, 0, 0);
  fill-rule: nonzero;
  opacity: 1;
  stroke-linecap: round;
}
.order-status-card .failIco svg g path:nth-child(2) {
  stroke: none;
  stroke-width: 1;
  stroke-dasharray: none;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 10;
  fill: rgb(236, 0, 0);
  fill-rule: nonzero;
  opacity: 1;
  stroke-dasharray: round;
}
.order-status-card .failIco svg g path:nth-child(3) {
  stroke: none;
  stroke-width: 1;
  stroke-dasharray: none;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 10;
  fill: rgb(236, 0, 0);
  fill-rule: nonzero;
  opacity: 1;
  stroke-linecap: round;
}

.success_btn {
  background: rgb(47, 191, 62);
  color: #fff;
}

.fail_btn {
  background: rgb(191, 47, 47);
  color: #fff;
}/*# sourceMappingURL=App.css.map */